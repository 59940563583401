export const site = {
  color: "#fae8e8",
  background: "black",

  gutter: "4vw",
  maxWidth: "1800px",
  insetPadding: "0vw",

  mobileGutter: "6vw",
};
